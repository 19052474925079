/**
 * General loading state
 * @type {String}
 */
export const SET_LOADING = 'SET_LOADING'

/**
 * Challenge Space Actions
 * @type {String}
 */
export const SET_FEATURED_CHALLENGE_SPACES = 'SET_FEATURED_CHALLENGE_SPACES'
export const SET_CHALLENGE_SPACES = 'SET_CHALLENGE_SPACES'
export const LOAD_MORE_CHALLENGE_SPACES = 'LOAD_MORE_CHALLENGE_SPACES'
export const SET_SUBMIT_CHALLENGE_SPACE = 'SET_SUBMIT_CHALLENGE_SPACE'
export const SET_ACCOUNT_CHALLENGE_SPACES = 'SET_ACCOUNT_CHALLENGE_SPACES'
export const FILTER_ACCOUNT_CHALLENGE_SPACES = 'FILTER_ACCOUNT_CHALLENGE_SPACES'
export const SET_ACCOUNT_CHALLENGE_SPACE = 'SET_ACCOUNT_CHALLENGE_SPACE'
export const CLEAR_ACCOUNT_CHALLENGE_SPACE = 'CLEAR_ACCOUNT_CHALLENGE_SPACE'
export const ADD_ACCOUNT_CHALLENGE_SPACE = 'ADD_ACCOUNT_CHALLENGE_SPACE'
export const UPDATE_CHALLENGE_SPACE_STATE = 'UPDATE_CHALLENGE_SPACE_STATE'
export const UPDATE_CHALLENGE_SPACE_CHALLENGE_RECRUITMENT_STATE =
	'UPDATE_CHALLENGE_SPACE_CHALLENGE_RECRUITMENT_STATE'
export const UPDATE_CHALLENGE_SPACE_CHALLENGE_RANKING_STATE =
	'UPDATE_CHALLENGE_SPACE_CHALLENGE_RANKING_STATE'
export const UPDATE_CHALLENGE_SPACE_TEAM_RANKING_STATE =
	'UPDATE_CHALLENGE_SPACE_TEAM_RANKING_STATE'
export const UPDATE_CHALLENGE_SPACE_TEAM_REGISTRATION_STATE =
	'UPDATE_CHALLENGE_SPACE_TEAM_REGISTRATION_STATE'
export const SET_ACCOUNT_CHALLENGE_SPACE_DISTRIBUTION =
	'SET_ACCOUNT_CHALLENGE_SPACE_DISTRIBUTION'
export const SET_ACCOUNT_CHALLENGE_SPACE_RANKINGS =
	'SET_ACCOUNT_CHALLENGE_SPACE_RANKINGS'
export const SET_ACCOUNT_CHALLENGE_SPACE_MAILINGS =
	'SET_ACCOUNT_CHALLENGE_SPACE_MAILINGS'
export const SET_ACCOUNT_CHALLENGE_SPACE_NOTE =
	'SET_ACCOUNT_CHALLENGE_SPACE_NOTE'
export const SET_ACCOUNT_CHALLENGE_SPACE_TEAM_FORMATION_CONFIG =
	'SET_ACCOUNT_CHALLENGE_SPACE_TEAM_FORMATION_CONFIG'
export const UPDATE_CHALLENGE_SPACE_TEAM_FORMATION_STATE =
	'UPDATE_CHALLENGE_SPACE_TEAM_FORMATION_STATE'
export const UPDATE_CHALLENGE_SPACE_STUDENT_REGISTRATION_STATE =
	'UPDATE_CHALLENGE_SPACE_STUDENT_REGISTRATION_STATE'
export const DUPLICATE_CHALLENGE_SPACE = 'DUPLICATE_CHALLENGE_SPACE'
export const SET_CHALLENGE_SPACE_STUDENTS = 'SET_CHALLENGE_SPACE_STUDENTS'

/**
 * Authentication actions
 * @type {String}
 */
export const AUTHENTICATE = 'AUTHENTICATE'
export const DEAUTHENTICATE = 'DEAUTHENTICATE'

/**
 * User actions
 * @type {String}
 */
export const USER = 'USER'
export const UPDATE_AUTH_USER = 'UPDATE_AUTH_USER'
export const USER_PROFILES = 'USER_PROFILES'
export const SET_USER_PROFILE = 'SET_USER_PROFILE'
export const REMOVE_AUTH_CHALLENGE = 'REMOVE_AUTH_CHALLENGE'

/**
 * User company actions
 */
export const UPDATE_OWN_COMPANY = 'UPDATE_OWN_COMPANY'
export const CREATE_OWN_COMPANY = 'CREATE_OWN_COMPANY'
export const SET_SELECTED_COMPANY = 'SET_SELECTED_COMPANY'
export const UPDATE_SELECTED_COMPANY = 'UPDATE_SELECTED_COMPANY'

/**
 * Error actions
 * @type {String}
 */
export const ERROR = 'ERROR'

/**
 * Challenge space challenges
 * @type {String}
 */
export const SET_CHALLENGE_SPACE_CHALLENGES = 'SET_CHALLENGE_SPACE_CHALLENGES'
export const UPDATE_CHALLENGE_SPACE_CHALLENGES =
	'UPDATE_CHALLENGE_SPACE_CHALLENGES'
export const UPDATE_CHALLENGE_SPACE_CHALLENGE =
	'UPDATE_CHALLENGE_SPACE_CHALLENGE'
export const REMOVE_CHALLENGE_SPACE_CHALLENGE =
	'REMOVE_CHALLENGE_SPACE_CHALLENGE'
export const UPDATE_CHALLENGE_SPACE_CHALLENGE_USERS =
	'UPDATE_CHALLENGE_SPACE_CHALLENGE_USERS'

/**
 * Account users
 * @type {String}
 */
export const SET_ACCOUNT_USERS = 'SET_ACCOUNT_USERS'
export const FILTER_ACCOUNT_USERS = 'FILTER_ACCOUNT_USERS'
export const UPDATE_USER_STATE = 'UPDATE_USER_STATE'
export const ADD_ACCOUNT_USER = 'ADD_ACCOUNT_USER'
export const REMOVE_ACCOUNT_USER = 'REMOVE_ACCOUNT_USER'

/**
 * Account companies
 * @type {String}
 */
export const SET_ACCOUNT_COMPANIES = 'SET_ACCOUNT_COMPANIES'
export const FILTER_ACCOUNT_COMPANIES = 'FILTER_ACCOUNT_COMPANIES'

/**
 * Account organisations
 * @type {String}
 */
export const SET_ACCOUNT_ORGANISATIONS = 'SET_ACCOUNT_ORGANISATIONS'
export const ADD_ACCOUNT_ORGANISATION = 'ADD_ACCOUNT_ORGANISATION'
export const FILTER_ACCOUNT_ORGANISATIONS = 'FILTER_ACCOUNT_ORGANISATIONS'
export const SET_PUBLIC_ORGANISATIONS = 'SET_PUBLIC_ORGANISATIONS'
export const REMOVE_ACCOUNT_ORGANISATION = 'REMOVE_ACCOUNT_ORGANISATION'
export const UPDATE_ORGANISATION_STATE = 'UPDATE_ORGANISATION_STATE'
export const SET_SELECTED_ORGANISATION = 'SET_SELECTED_ORGANISATION'

/**
 * Account organisation types
 * @type {String}
 */
export const SET_ACCOUNT_EDUCATION_TYPES = 'SET_ACCOUNT_EDUCATION_TYPES'
export const SET_PUBLIC_EDUCATION_TYPES = 'SET_PUBLIC_EDUCATION_TYPES'

/**
 * Challenge space teams
 * @type {String}
 */
export const SET_CHALLENGE_SPACE_TEAMS = 'SET_CHALLENGE_SPACE_TEAMS'
export const CREATE_CHALLENGE_SPACE_TEAM = 'CREATE_CHALLENGE_SPACE_TEAM'
export const UPDATE_CHALLENGE_SPACE_TEAM = 'UPDATE_CHALLENGE_SPACE_TEAM'
export const SET_CHALLENGE_SPACE_TEAM = 'SET_CHALLENGE_SPACE_TEAM'
export const REMOVE_CHALLENGE_SPACE_TEAM = 'REMOVE_CHALLENGE_SPACE_TEAM'

/**
 * Account teams
 * @type {String}
 */
export const SET_ACCOUNT_TEAM = 'SET_ACCOUNT_TEAM'

/**
 * Account team challenge rankings
 * @type {String}
 */
export const SET_TEAM_CHALLENGE_RANKING = 'SET_TEAM_CHALLENGE_RANKING'

/**
 * Account company team rankings
 */
export const SET_TEAM_RANKING = 'SET_TEAM_RANKING'

/**
 * Account subjects
 * @type {String}
 */
export const SET_PUBLIC_SUBJECTS = 'SET_PUBLIC_SUBJECTS'
export const SET_ACCOUNT_SUBJECTS = 'SET_ACCOUNT_SUBJECTS'
export const FILTER_ACCOUNT_SUBJECTS = 'FILTER_ACCOUNT_SUBJECTS'

/**
 * Challenges
 * @type {String}
 */
export const UPDATE_CHALLENGE_STATE = 'UPDATE_CHALLENGE_STATE'
export const SET_ACCOUNT_CHALLENGE = 'SET_ACCOUNT_CHALLENGE'
export const UPDATE_ACCOUNT_CHALLENGE = 'UPDATE_ACCOUNT_CHALLENGE'
export const UPDATE_ACCOUNT_CHALLENGE_USERS = 'UPDATE_ACCOUNT_CHALLENGE_USERS'
export const UPDATE_SELECTED_CHALLENGE_USERS = 'UPDATE_SELECTED_CHALLENGE_USERS'
export const REMOVE_ACCOUNT_CHALLENGE = 'REMOVE_ACCOUNT_CHALLENGE'
export const SET_ACCOUNT_CHALLENGES = 'SET_ACCOUNT_CHALLENGES'
export const FILTER_ACCOUNT_CHALLENGES = 'FILTER_ACCOUNT_CHALLENGES'
export const SET_CHALLENGE_TEAMS = 'SET_CHALLENGE_TEAMS'
export const UPDATE_CHALLENGE_TEAMS = 'UPDATE_CHALLENGE_TEAMS'
export const SET_CHALLENGE_USERS = 'SET_CHALLENGE_USERS'
export const SET_ACCOUNT_CHALLENGE_NOTE = 'SET_ACCOUNT_CHALLENGE_NOTE'
export const SET_ACCOUNT_NEW_SPACE_CONFIG = 'SET_ACCOUNT_NEW_SPACE_CONFIG'
export const RESET_ACCOUNT_NEW_SPACE_CONFIG = 'RESET_ACCOUNT_NEW_SPACE_CONFIG'

/**
 * Team formation
 * @type {String}
 */
export const SET_TEAM_FORMATION_DEFAULT_QUESTIONS =
	'SET_TEAM_FORMATION_DEFAULT_QUESTIONS'
export const GET_TEAM_FORMATION_DISTRIBUTION = 'GET_TEAM_FORMATION_DISTRIBUTION'
export const CREATE_TEAM_FORMATION_DISTRIBUTION =
	'CREATE_TEAM_FORMATION_DISTRIBUTION'
export const GET_TEAM_FORMATION_CONFIG_WITH_DISTRIBUTION =
	'GET_TEAM_FORMATION_CONFIG_WITH_DISTRIBUTION'
export const GET_TEAM_FORMATION = 'GET_TEAM_FORMATION'
export const PUT_TEAM_FORMATION_DISTRIBUTION = 'PUT_TEAM_FORMATION_DISTRIBUTION'
export const TEAM_FORMATION_INFO = 'TEAM_FORMATION_INFO'
export const FINALIZE_TEAM_FORMATION = 'FINALIZE_TEAM_FORMATION'

import { useEffect } from 'react'
import { useRef } from 'react'
import { Role } from '../models/role'
import moment from 'moment'
// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.

/**
 * Simple debounce function
 * @type {[type]}
 */
export const debounce =
	(callback, time = 250, interval?) =>
	(...args) => {
		clearTimeout(interval)
		interval = setTimeout(() => {
			callback(...args)
		}, time)
	}

/**
 * Word counter (custom validator for 'simple-react-validator')
 * @type {[type]}
 */
export const countWords = (s: string) => {
	if (typeof s !== 'string') {
		return -1 // Fallback for when parameter is not a string, -1 = falsey
	} else if (s === '') {
		return 0
	}
	// \S === match anything that is not whitespace
	// + === match one or more preceding characters
	return s.match(/\S+/g)?.length
}

/**
 * Helper function for roles check
 * @type {Boolean}
 */
export const isAllowed = (user: any, roles: Role[] = []) => {
	if (user && roles.indexOf(user.attributes.role) >= 0) {
		return true
	} else {
		return false
	}
}

/**
 * Generates a random uuid
 * @type {String}
 */
export const uuidv4 = () => {
	return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
		var r = (Math.random() * 16) | 0,
			v = c == 'x' ? r : (r & 0x3) | 0x8
		return v.toString(16)
	})
}

/**
 * Rounds a number to two decimals
 * @type {Number}
 */
export const roundToTwo = (num: number) => {
	return Math.round((num + Number.EPSILON) * 100) / 100
}

/**
 * Random password generator
 * @type {[type]}
 */
export const generatePassword = (length = 8) => {
	var charset =
			'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789',
		retVal = ''
	for (var i = 0, n = charset.length; i < length; ++i) {
		retVal += charset.charAt(Math.floor(Math.random() * n))
	}
	return retVal
}

/**
 * Check if image exists
 *
 * @param   {String}  imageSrc  original image
 * @param   {Function}  good      callback for good
 * @param   {Function}  bad       callback for bad
 *
 * @return  {Function}            returns a good or bad callback
 */
export const imageExists = (imageSrc: string, good, bad) => {
	var img = new Image()
	img.onload = good
	img.onerror = bad
	img.src = imageSrc
}

/**
 * Get teams from rankings and sort them by:
 * - alphabetical team name
 * - if it has a submitted a challenge ranking or not
 *
 * @param   {any[]}  rankings
 *
 * @return  {Array}
 */
export const getRankingTeams = (rankings: any) => {
	return rankings && rankings.team && Object.keys(rankings.team).length > 0
		? Object.keys(rankings.team)
				.map((key) => rankings.team[key])
				.sort((a, b) => {
					return a.attributes.name.toUpperCase() >
						b.attributes.name.toUpperCase()
						? 1
						: -1
				})
				.sort((a, b) => {
					const aHasRanking = a.relationships.challengeRanking.data
					const bHasRanking = b.relationships.challengeRanking.data
					if (aHasRanking && !bHasRanking) {
						return -1
					} else {
						return 1
					}
				})
		: []
}

/**
 * Get challenges from rankings and sort them by:
 * - alphabetical company name
 * - if the company has submitted a team ranking or not
 *
 * @param   {any}  rankings  [rankings description]
 *
 * @return  {[type]}         [return description]
 */
const getChallengesRelationId = (challenge) => {
	return challenge.attributes.isInternalChallenge
		? challenge.attributes.organisationId
		: challenge.attributes.companyId
}
export const getRankingChallenges = (rankings: any) => {
	return rankings &&
		rankings.challenge &&
		Object.keys(rankings.challenge).length > 0
		? Object.keys(rankings.challenge)
				.map((key) => rankings.challenge[key])
				.filter((current) => current.attributes.state === 'approved')
				.sort((a, b) => {
					return getChallengesRelationId(a) > getChallengesRelationId(b)
						? 1
						: -1
				})
				.sort((a, b) => {
					const aHasRanking = a.relationships.teamRanking.data
					const bHasRanking = b.relationships.teamRanking.data
					if (aHasRanking && !bHasRanking) {
						return -1
					} else {
						return 1
					}
				})
		: []
}

/**
 * Get popularity factor
 *
 * @param   {Array}  rankingTeams
 * @param   {Number} inRankingCount
 * @param   {Number} averagePosition
 *
 * @return  {Number}
 */
export const getPopularityFactor = (
	rankingTeams: any[],
	inRankingCount,
	averagePosition
) => {
	const teamCount = rankingTeams
		? rankingTeams.filter((team) => team.relationships.challengeRanking.data)
				.length
		: 0

	if (!averagePosition || teamCount === 0) {
		return 0
	}

	return (inRankingCount / teamCount / averagePosition) * 100
}

/**
 * get challenges from ranking sorted by popularity
 *
 * @param   {Object}  rankings
 *
 * @return  {Array}
 */
export const getRankingChallengesByPopularity = (rankings: any) => {
	const teams = getRankingTeams(rankings)

	return rankings &&
		rankings.challenge &&
		rankings.challengeRankingItem &&
		Object.keys(rankings.challenge).length > 0
		? Object.keys(rankings.challenge)
				.map((key) => {
					const challenge = rankings.challenge[key]

					//Return null if challenge is not approved - This will be filtered in lower function
					if (challenge.attributes.state !== 'approved') return null

					let teamRankingPositions = []
					let challengeInTeamRankingCount = 0
					let average = (array) => array.reduce((a, b) => a + b) / array.length

					const challengeRankingItems = Object.keys(
						rankings.challengeRankingItem
					).map((key) => rankings.challengeRankingItem[key])

					for (let i = 0; i < challengeRankingItems.length; i++) {
						if (
							challengeRankingItems[i].attributes.challengeId === challenge.id
						) {
							challengeInTeamRankingCount++
							teamRankingPositions.push(
								challengeRankingItems[i].attributes.position + 1
							)
						}
					}

					const averagePosition =
						teamRankingPositions.length > 0
							? average(teamRankingPositions)
							: null

					const pFactor = getPopularityFactor(
						teams,
						challengeInTeamRankingCount,
						averagePosition
					)

					challenge.attributes.averagePosition = averagePosition
					challenge.attributes.challengeInTeamRankingCount =
						challengeInTeamRankingCount
					challenge.attributes.challengePopularity = pFactor

					return challenge
				})
				.filter((current) => current !== null) //Filter nullable
				.sort((a, b) => {
					if (
						a.attributes.averagePosition == 'null' &&
						b.attributes.averagePosition !== 'null'
					) {
						return 1
					} else {
						return -1
					}
				})
				.sort((a, b) => {
					if (
						a.attributes.challengePopularity > b.attributes.challengePopularity
					) {
						return -1
					} else if (
						a.attributes.challengePopularity == b.attributes.challengePopularity
					) {
						return 0
					} else {
						return 1
					}
				})
		: []
}

export const usePrevious = (value) => {
	const ref = useRef()
	useEffect(() => {
		ref.current = value
	})
	return ref.current
}

export const dateToMysqlDate = (dateString: string) => {
	return moment(dateString).format('YYYY-MM-DD').toString()
}

export const dateToMysqlDateOrEmpty = (dateString: any) => {
	if (dateString == null || dateString == '') {
		return ''
	} else {
		return dateToMysqlDate(dateString)
	}
}

export const dateToDisplayDate = (dateString: any) => {
	return moment(dateString).format('DD-MM-YYYY').toString()
}

export const dateToDisplayDateOrEmpty = (
	dateString: any,
	emptyValue: string = ''
) => {
	if (dateString == null || dateString == '') {
		return emptyValue
	} else {
		return dateToDisplayDate(dateString)
	}
}

export const isArrayWithLength = (a) => {
	return Array.isArray(a) && a?.length
}

export const mapIdsToArray = (res) => {
	if (!res) {
		return []
	}
	let keyed = Object.keys(res)
	if (keyed.length) {
		return keyed.map((key) => {
			return res[key]
		})
	} else {
		return []
	}
}
export const mapIdsToArrayForceAttributes = (res) => {
	if (!res) {
		return []
	}
	let keyed = Object.keys(res)
	if (keyed.length) {
		return keyed.map((key) => {
			return mapAttributesToObject(res[key])
		})
	} else {
		return []
	}
}

export const mapAttributesToObject = (obj) => {
	if (obj?.attributes) {
		obj = { ...obj, ...obj.attributes }
		delete obj.attributes
	}
	return obj
}

export const mapObjectIds = (object) => {
	if (!object) {
		return []
	}
	return Object.keys(object)
}

export const mapIdsFromRelation = (object, relation) => {
	if (!object?.relationships?.[relation]) {
		return []
	}
	return object?.relationships?.[relation]?.data.map((tmp) => tmp.id)
}

export const mapResultKeyToArray = (res, key) => {
	if (!res || !res[key]) {
		return []
	}
	let keyed = Object.keys(res[key])
	if (keyed.length) {
		return keyed.map((index) => {
			return res[key][index]
		})
	} else {
		return []
	}
}

export const mapRelationToIdArray = (obj, relationKey) => {
	if (
		obj?.relationships[relationKey]?.data &&
		obj?.relationships[relationKey]?.data.length
	) {
		return obj?.relationships[relationKey]?.data.map((item) => {
			return item.id
		})
	} else {
		return []
	}
}

export const defaultParams = {
	filter: {
		q: '',
	},
	page: {
		offset: 0,
		limit: 20,
	},
	sort: '-created_at',
}

export const capitalizeFirstLetter = (string) => {
	return string.charAt(0).toUpperCase() + string.slice(1)
}

export const scrollToId = (elementId: string) => {
	const yOffset = -90
	const element = document.getElementById(elementId)
	if (element) {
		const y = element.getBoundingClientRect().top + window.scrollY + yOffset
		window.scrollTo({ top: y, behavior: 'smooth' })
	}
}

export const plurify = (
	string: string,
	countable: any[] | number | boolean
) => {
	if (typeof countable == 'number') {
		return string + (countable === 1 ? '' : 's')
	} else if (typeof countable == 'boolean') {
		return string + (countable === false ? '' : 's')
	} else {
		return (
			string +
			(typeof countable !== 'undefined' && countable.length === 1 ? '' : 's')
		)
	}
}

export const firstInObjectIfOnlyOne = (obj, defaultValue = null) => {
	return Object.keys(obj).length == 1 ? obj[Object.keys(obj)[0]] : defaultValue
}

export const firstOrNull = (obj) => {
	return obj ? obj[Object.keys(obj)[0]] : null
}

export const firstOrDefault = (obj, defaultValue = null) => {
	return obj ? obj[Object.keys(obj)[0]] : defaultValue
}

export const prependObjectKeys = (pre: string, obj: any) => {
	return Object.fromEntries(
		Object.entries(obj).map(([key, value]) => [pre + key, value])
	)
}

export const intersects = (a, b) => {
	return a.filter((x) => b.includes(x)).length > 0
}
